import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import branding from "../images/branding.svg"
import champion from "../images/champion.svg"

const Branding = () => {
  return (
    <Layout>
      <main id="main" style={{ backgroundColor: "rgb(36, 41, 67)" }}>
        <h2 className="text-center text-4xl my-16">Branding</h2>
        <div className="grid grid-cols-3 grid-auto-rows px-16 md:px-32 lg:px-64">
          <div className="col-start-1 col-span-3 grid-row-1 md:col-span-2 row-start-1 my-16 pt-5">
            <h2 className="text-2xl font-bold pl-5">
              What exactly is branding?
            </h2>
            <p className="text-xl p-5">
              Branding is a system of identifying a product, service, or company
              as unique and differentiating it from the competition. Branding
              can also be defined as the experience that your target audience
              get when they come in contact with your brand, product or service.
              On a visual level, this can be done through names, images,
              designs, or other identifying features as well as a robust message
              strategy.
            </p>
          </div>

          <div className="col-start-1 col-span-3 md:col-span-1 row-start-2 md:row-start-1 my-16">
            <img
              src={branding}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>

          <div className="col-start-1 col-span-3 md:col-start-2 row-start-3 md:row-start-2 my-16">
            <h2 className="text-2xl font-bold pl-5">Seperate yourself from the competition.</h2>
            <p className="text-xl p-5">
            Regardless of your industry, you always need to consider your competitors and what you can do to set your business apart. With the right branding, this can be achieved.
            </p>
          </div>
          <div className="col-start-1 col-span-3 md:col-span-1 row-start-4 md:row-start-2 my-16">
            <img
              src={champion}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>
          <div className="col-start-1 col-span-3 md:col-span-2 my-16">
            {/* <h2 className="text-2xl">Product Development</h2>
            <p>
              We use a multi-disciplinary approach encompassing the following
              methods:
            </p>
            <div className="w-full flex ">
              <ol className="list-disc list-inside">
                <li>Agile Development</li>
                <li>Project Management</li>
                <li>Wire Framing</li>
                <li>User Stories</li>
                <li>Scoping</li>
                <li>Visual Design</li>
              </ol>
            </div> */}
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Branding
